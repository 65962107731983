import $ from 'jquery';

$.fn.animateRotate = function(startAngle, endAngle, duration, easing, complete){
  return this.each(function(){
    var elem = $(this);

    $({deg: startAngle}).animate({deg: endAngle}, {
      duration: duration,
      easing: easing,
      queue: false,
      step: function(now){
        elem.css({
          '-moz-transform':'rotate('+now+'deg)',
          '-webkit-transform':'rotate('+now+'deg)',
          '-o-transform':'rotate('+now+'deg)',
          '-ms-transform':'rotate('+now+'deg)',
          'transform':'rotate('+now+'deg)'
        });
      },
      complete: complete || $.noop
    });
  });
};
function wait(ms) {
  if (ms <= 0) {
    return Promise.resolve();
  }
  return new Promise(resolve => setTimeout(resolve, ms));
}

const root = $('#SCHEMA_ROOT');

let lastPlayed = null;

let ring;
let column1;
let column2;
let light;

let chains = {}
async function showChains(firstChain, secondChain, firstStarting = true) {
  column1.css({
    left: 117,
    height: 0,
  });
  column2.css({
    right: 109,
    height: 0,
  });
  column1.animate({
    height: 41,
    opacity: 1,
  }, 500);
  column2.animate({
    height: 41,
    opacity: 1,
  }, 500);
  await wait(100);
  firstChain.css({
    left: 83,
  });
  secondChain.css({
    right: 75,
  });
  firstChain.animate({
    bottom: 67,
    opacity: 1,
  }, 500);
  secondChain.animate({
    bottom: 67,
    opacity: 1,
  }, 500);
  await wait(150);

  ring.css({
    left: firstStarting ? 74.5 : 'unset',
    right: firstStarting ? 'unset' : 67,
  });
  ring.fadeIn(500, function () {
    ring.fadeOut(800);
  });
  await wait(800);
}

async function hideChains(firstChain, secondChain, firstStarting = true) {
  ring.css({
    left: firstStarting ? 'unset' : 74,
    right: firstStarting ? 67 : 'unset',
  });
  ring.fadeIn(500, function () {
    ring.fadeOut(800);
  });
  await wait(1500);
  firstChain.animate({
    bottom: 48,
    opacity: 0,
  }, 500);
  secondChain.animate({
    bottom: 48,
    opacity: 0,
  }, 500);
  column1.animate({
    opacity: 0,
    height: 0
  }, 250);
  column2.animate({
    opacity: 0,
    height: 0,
  }, 250);
  await wait(700);
  for (let i = 0 ; i < Object.keys(chains).length; i++) {
    const chain = Object.values(chains)[i];
    chain.css({
      left: 'unset',
      right: 'unset',
      bottom: 44,
      opacity: 0,
    });
  }
}

async function rotateLight(deg) {
  light.animateRotate(deg === -90 ? 0 : -90, deg, 220, 'linear');
  if (deg === -180) {
    light.animate({
      left: 264,
      top: 113,
    }, 110);
  } else {
    light.animate({
      left: 123,
      top: 118
    }, 110);
  }
  await wait(200);
}
async function startSchemaAnimation(c1, c2) {
  await showChains(c1, c2);
  const lightDurationFactor = 300;
  const lightParams = {
    startTop: 85,
    startLeft: 117,
    points: [
      { top: 112, left: 117, duration: lightDurationFactor },
      { top: 118, left: 254, duration: lightDurationFactor * 9 / 3 },
      { top: 85, left: 264, duration: lightDurationFactor },
    ]
  }
  light.css({
    display: 'block',
    transform: 'none',
    top: lightParams.startTop,
    left: lightParams.startLeft,
  });
  let point = lightParams.points[0];
  light.animate({
    top: point.top,
    left: point.left,
  }, point.duration, 'linear');
  await wait(point.duration - 100);
  await rotateLight(-90);

  point = lightParams.points[1];
  light.animate({
    top: point.top,
    left: point.left,
  }, point.duration, 'linear');
  await wait(point.duration - 60);
  await rotateLight(-180);

  point = lightParams.points[2];
  light.animate({
    top: point.top,
    left: point.left,
  }, point.duration, 'linear');
  setTimeout(() => {
    light.css({
      display: 'none',
      transform: 'none',
    })
  }, point.duration);


  await hideChains(c1, c2);
}

async function flushOut() {
  $('#SCHEMA_RING').remove();
  $('#SCHEMA_COLUMN_1').remove();
  $('#SCHEMA_COLUMN_2').remove();
  $('#SCHEMA_LIGHT').remove();
  $('#SCHEMA_C_SOLANA').remove();
  $('#SCHEMA_C_BSC').remove();
  $('#SCHEMA_C_AVALANCHE').remove();
  $('#SCHEMA_C_ARBITRUM').remove();
  $('#SCHEMA_C_ETHEREUM').remove();
  $('#SCHEMA_C_POLYGON').remove();
  const new_c_1 = `<div class="schema-column" id="SCHEMA_COLUMN_1"></div>`
  const new_c_2 = `<div class="schema-column" id="SCHEMA_COLUMN_2"></div>`
  const new_light = `<img src="https://cdn.mayan.finance/light.png" class="schema-light" id="SCHEMA_LIGHT" />`
  const new_ring = `<img src="https://cdn.mayan.finance/ring.png" class="schema-ring" id="SCHEMA_RING" />`
  const new_c_sol = `<img src="https://cdn.mayan.finance/c_solana.png" class="schema-chain" id="SCHEMA_C_SOLANA" />`
  const new_c_bsc = `<img src="https://cdn.mayan.finance/c_bsc.png" class="schema-chain" id="SCHEMA_C_BSC" />`
  const new_c_avax = `<img src="https://cdn.mayan.finance/c_avalanche.png" class="schema-chain" id="SCHEMA_C_AVALANCHE" />`
  const new_c_arb = `<img src="https://cdn.mayan.finance/c_arbitrum.png" class="schema-chain" id="SCHEMA_C_ARBITRUM" />`
  const new_c_pol = `<img src="https://cdn.mayan.finance/c_polygon_fix.png" class="schema-chain" id="SCHEMA_C_POLYGON" />`
  const new_eth = `<img src="https://cdn.mayan.finance/c_ethereum.png" class="schema-chain" id="SCHEMA_C_ETHEREUM" />`
  const new_base = `<img src="https://cdn.mayan.finance/c_base.png" class="schema-chain" id="SCHEMA_C_BASE" />`
  root.append(new_c_1, new_c_2, new_light, new_ring, new_c_sol, new_c_bsc, new_c_avax, new_c_arb, new_c_pol, new_eth, new_base);

  ring = $('#SCHEMA_RING');
  column1 = $('#SCHEMA_COLUMN_1');
  column2 = $('#SCHEMA_COLUMN_2');
  light = $('#SCHEMA_LIGHT');

  chains = {
    solana: $('#SCHEMA_C_SOLANA'),
    bsc: $('#SCHEMA_C_BSC'),
    avalanche: $('#SCHEMA_C_AVALANCHE'),
    arbitrum: $('#SCHEMA_C_ARBITRUM'),
    ethereum: $('#SCHEMA_C_ETHEREUM'),
    polygon: $('#SCHEMA_C_POLYGON'),
    base: $('#SCHEMA_C_BASE'),
  }
}

const allChains = ['solana', 'bsc', 'avalanche', 'arbitrum', 'ethereum', 'polygon', 'base'];
function getChains() {
  let new_c_1, new_c_2;
  do {
    new_c_1 = allChains[Math.floor(Math.random() * allChains.length)];
  } while (lastPlayed && lastPlayed.includes(new_c_1));
  do {
    new_c_2 = allChains[Math.floor(Math.random() * allChains.length)];
  } while (new_c_2 === new_c_1 || (lastPlayed && lastPlayed.includes(new_c_2)));
  lastPlayed = [new_c_1, new_c_2];
  return [chains[new_c_1], chains[new_c_2]];
}
async function loopAnimation() {
  while (true) {
    await wait(400);
    await flushOut();
    const [c1, c2] = getChains();
    await startSchemaAnimation(c1, c2);
    await wait(300);
  }
}
$(document).ready(function () {
  loopAnimation();
});
